import { useState, useRef } from "react";
import get from "lodash/get";
import {
  Box,
  Fade,
  Link,
  Paper,
  Popper,
  Typography,
  styled,
} from "@mui/material";
import { DeliveryStatsProps } from "./types";
import CampaignCutoffInfo from "components/CampaignCutoffInfo";
import CampaignIcon from "icons/CampaignIcon";

const Bar = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flex: "0 0 auto",
    height: "9px",
    width: "100%",
    borderRadius: "20px",
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    position: "relative",
    zIndex: 1,
    overflow: "hidden",
    transition: "all ease-in-out 0.15s",
  };
});

const PopperContent = styled("div")(({ theme }) => {
  return {
    padding: "15px",
    width: "300px",
    color: theme.palette.text.primary,
    whiteSpace: "normal",
    fontSize: "14px",
    lineHeight: "28px",
  };
});

const PopperHeader = styled("header")(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    paddingBottom: "10px",
  };
});

const PopperFooter = styled("footer")(({ theme }) => {
  return {
    fontSize: "12px",
    padding: "10px",
  };
});

const PopperButton = styled(Link)(({ theme }) => {
  return {
    color: theme.palette.primary.main,
    fontWeight: 600,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  };
});

const LegendRow = styled("div")(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    height: "35px",
  };
});

const LegendParagraph = styled("p")({
  margin: 0,
  lineHeight: "40px",
  fontWeight: 600,
});

const LegendKey = styled("div")(({ theme }) => {
  return {
    height: "20px",
    width: "20px",
    marginRight: "10px",
    borderRadius: "50%",
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
  };
});

function DeliveryStats({ currentAccount, deliveryStats }: DeliveryStatsProps) {
  const popperAnchorRef = useRef({});
  const hoverTimeout = useRef<number | null>(null);
  const [open, setOpen] = useState(false);

  const supportLink = get(
    currentAccount,
    ["settings", "supportLink", "value"],
    "https://help.textus.com/s",
  );

  const handleClick = () => {
    if (hoverTimeout.current !== null) {
      clearTimeout(hoverTimeout.current);
    }
    setOpen(true);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    setOpen(false);
  };

  const handleMouseEnter = () => {
    hoverTimeout.current = window.setTimeout(() => {
      setOpen(true);
    }, 300);
  };

  const { campaignsResponsiveness = false } = currentAccount.featureFlags;

  const {
    campaignDailyLimit = 0,
    campaignMessagesScheduled = 0,
    campaignMessagesUsed = 0,
  } = deliveryStats ?? {};
  const sentLength = (campaignMessagesUsed / campaignDailyLimit) * 100;
  const scheduledLength =
    ((campaignMessagesScheduled + campaignMessagesUsed) / campaignDailyLimit) *
    100;
  return (
    <Box
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-product-tour="CampaignUsage"
      ref={popperAnchorRef}
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding="0.5rem 0.75rem 1rem 0.75rem"
      position="relative"
      flex="0 0 auto"
      overflow="hidden"
      height="auto"
      sx={(theme) => {
        return {
          borderBottom: `1px solid ${theme.palette.divider}`,
          flexFlow: "column nowrap",
        };
      }}
    >
      {campaignDailyLimit && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Box fontWeight={500}>
            {/* TODO: Wnen flag is removed, delete CampaignCutoffInfo */}
            {campaignsResponsiveness ? null : (
              <CampaignCutoffInfo currentAccount={currentAccount} />
            )}
            <Typography variant="label" color="text.secondary">
              {campaignMessagesUsed + campaignMessagesScheduled} of{" "}
              {campaignDailyLimit} messages used today
            </Typography>
          </Box>
          <Bar>
            <Box
              sx={(theme) => {
                return {
                  position: "absolute",
                  width: `${sentLength}%`,
                  top: 0,
                  left: 0,
                  height: "100%",
                  zIndex: 5,
                  borderRadius: "20px",
                  transition: "all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.3s",
                  background: theme.palette.primary.main,
                };
              }}
            />
            <Box
              height="100%"
              left={0}
              position="absolute"
              top={0}
              width={`${scheduledLength}%`}
              sx={({ palette }) => {
                return {
                  backgroundColor:
                    scheduledLength > 79
                      ? palette.error.main
                      : palette.secondary.main,
                  backgroundSize: "200% 200%",
                  borderRadius: "20px",
                  transition: "all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.3s",
                  zIndex: 3,
                };
              }}
            />
          </Bar>
        </Box>
      )}
      {/* TODO: When flag is removed, delete Popper altogether */}
      {campaignsResponsiveness ? null : (
        <Popper
          anchorEl={popperAnchorRef.current}
          open={open}
          placement="bottom-end"
          transition
        >
          {({ TransitionProps }) => {
            return (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <PopperContent
                    sentLength={sentLength}
                    scheduledLength={scheduledLength}
                  >
                    <PopperHeader>
                      <CampaignIcon color="primary" />
                      <LegendParagraph
                        color="primary.main"
                        sx={{ fontWeight: 700, marginLeft: "12px" }}
                      >
                        Daily Campaign Message Usage
                      </LegendParagraph>
                    </PopperHeader>
                    <LegendRow>
                      <LegendKey
                        sx={{
                          backgroundColor:
                            sentLength > 79 ? "error.main" : "primary.main",
                        }}
                      />
                      <LegendParagraph>
                        Used: {campaignMessagesUsed}
                      </LegendParagraph>
                    </LegendRow>
                    <LegendRow>
                      <LegendKey
                        sx={{
                          backgroundColor:
                            scheduledLength > 79
                              ? "error.main"
                              : "secondary.main",
                        }}
                      />
                      <LegendParagraph>
                        Scheduled: {campaignMessagesScheduled}
                      </LegendParagraph>
                    </LegendRow>
                    <LegendRow>
                      <LegendKey />
                      <LegendParagraph>
                        Daily Limit: {campaignDailyLimit}
                      </LegendParagraph>
                    </LegendRow>
                    <PopperFooter>
                      {supportLink && (
                        <PopperButton
                          href={supportLink}
                          style={{ verticalAlign: "baseline" }}
                          target="_new"
                        >
                          Contact support
                        </PopperButton>
                      )}{" "}
                      to increase your limit.
                    </PopperFooter>
                  </PopperContent>
                </Paper>
              </Fade>
            );
          }}
        </Popper>
      )}
    </Box>
  );
}

export { DeliveryStats };
